module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NQ4QD9","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":0},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"RBcom","short_name":"RBcom","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"/codebuild/output/src4171579566/src/phx-reckitt/packages/gatsby-theme-husky/static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fe5a19c3c0212dac49cab0e121d364e7"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"gatsby-plugin-nprogress","options":{"color":"transparent","showSpinner":false}}],"configDir":"/codebuild/output/src4171579566/src/phx-reckitt/packages/gatsby-theme-husky/gatsby","projectRoot":"/codebuild/output/src4171579566/src/phx-reckitt/packages/gatsby-theme-husky","babel":false,"tsNode":true},
    }]
